(function() {
    window.hasPremium = false;

    window.checkPremium = function() {

        window.services.getPayments()
        .then(function (response) {
    
            for (let payment of response.data) {
    
                if (payment.succeeded == true) {
                    window.hasPremium = true;
                    return;
                }
    
            }
    
        });

        window.hasPremium = false;

    }

    window.checkPremium();

    window.utils = {
        rand(min, max) {
            return Math.floor(Math.random() * (max + 1)) + min;
        },
        hash(name) {
            let hash = window.location.hash.substr(1);
            let params = new URLSearchParams(hash);
            
            return params.get(name);
        },
        url: {
            params: function({url}) {
                let paramsObject = {};
                let params = url.split('?')

                if (params.length && params.length > 1) {
                    params[1].split('&');
                } else {
                    return [];
                }

                for (let param of params) {
                    let p = param.split('=');
                    paramsObject[`${p[0]}`] = p[1];
                }
                return paramsObject;
            },
            param: function({url, name}) {
                let params = window.utils.url.params({url: url});
                return params[`${name}`] ? params[name] : null;
            }
        },
        cookies: {
            has(name) {
                return !!this.get(name);
            },
            get(name) {
                let nameEQ = name + "=";
                let ca = document.cookie.split(';');
                for (let i = 0; i < ca.length; i++) {
                    let c = ca[i];
                    while (c.charAt(0) == ' ')
                    c = c.substring(1, c.length);
                    if (c.indexOf(nameEQ) == 0)
                    return decodeURI(c.substring(nameEQ.length, c.length));
                }
                return null;
            },
            set(name, value, days) {
                let expires = "";
                if (days) {
                    let date = new Date();
                    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                    expires = "; expires=" + date.toGMTString();
                }
                //Adds the SameSite stuff for production. Adding it to dev breaks the dev site.
                let sameSite = window.location.hostname == 'localhost' ? '' : 'SameSite=None; Secure;';
                document.cookie = `${name}=${encodeURI(value) + expires}; ${sameSite} path=/`;
            },
            delete(name) {
                this.set(name, '', -1);
            },
            deleteAll() {
                let cookies = document.cookie.split(";");

                for (let i = 0; i < cookies.length; i++) {
                    let cookie = cookies[i];
                    let eqPos = cookie.indexOf("=");
                    let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                    this.set(name, '', -1);
                }
            }
        },
        validateEmail(email) {
            const re = new RegExp(
                '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[?[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\]?)|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
            );
            return re.test(String(email).toLowerCase());
        },
        validatePassword(password) {
            let re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!@#$%^&*]{6,}$/;
            return re.test(password);
        }
    }

})();